<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">设计信息</div>
            </div>
            <div class="descriptions-view" v-if="metadata">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">设计状态</span>
                      <span class="descriptions-item-content">
                          {{ designStatusMapper(metadata.state) }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件块数</span>
                      <span class="descriptions-item-content">
                          {{ designData && designData.number }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件尺寸</span>
                      <span class="descriptions-item-content">
                          {{ designData && showField(designData.size,'m') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件功率</span>
                      <span class="descriptions-item-content">
                          {{ designData && showField(designData.power, 'kw') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">逆变器功率</span>
                      <span class="descriptions-item-content">
                          {{ designData && showField(designData.npower, 'kw') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">设计文件</span>
                      <span class="descriptions-item-content">
                        <file-list :design-data="designDataFilesStr"></file-list>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">

                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">项目物料</div>
            </div>
            <div class="descriptions-view" v-if="metadata">
              <el-form :model="pageForm"
                       :rules="pageFormRules"
                       ref="formRef"
                       label-position="left"
                       label-width="100px">
                <el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item prop="order" required label="发货清单">
                        <el-upload class="upload-demo"
                                   action=""
                                   ref="filesUpload"
                                   list-type="picture"
                                   :multiple="true"
                                   :file-list="fileList"
                                   :show-file-list="false"
                                   :http-request="handlerUpload" >
                          <el-button size="small" type="primary">点击上传</el-button>
                          <div slot="tip" class="el-upload__tip">可上传图片和表格, 单个文件不能超过100MB</div>
                        </el-upload>
                        <ul class="el-upload-list el-upload-list--picture">
                          <li v-for="(iter, index) in fileList" :key="iter.name"
                              tabindex="0" class="el-upload-list__item is-success">
                            <img v-show="isImage(iter.name)" style="object-fit: cover;"
                                 :src="iter.url" alt="" class="el-upload-list__item-thumbnail">
                            <img v-show="!isImage(iter.name)" src="@/assets/img/file.png" alt="" class="el-upload-list__item-thumbnail">
                            <a class="el-upload-list__item-name">
                              <i class="el-icon-document"></i>{{ iter.name }}
                            </a>
                            <label class="el-upload-list__item-status-label">
                              <i class="el-icon-upload-success el-icon-check"></i>
                            </label>
                            <i @click="removeHandler(index)" class="el-icon-close"></i>
                          </li>
                        </ul>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-row>

                <el-row>
                  <el-col :span="8">
                    <el-form-item prop="driverName" required label="司机姓名">
                      <el-input v-model="pageForm.driverName"
                                style="width: 100%;"
                                :maxlength="10"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :offset="2" :span="8">
                    <el-form-item prop="driverPhone" required label="司机电话">
                      <el-input v-model="pageForm.driverPhone"
                                :maxlength="18"
                                style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item prop="takeName" required label="收货人名称">
                      <el-input v-model="pageForm.takeName"
                                style="width: 100%;"
                                :maxlength="10"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :offset="2" :span="8">
                    <el-form-item prop="takePhone" required label="收货人电话">
                      <el-input v-model="pageForm.takePhone"
                                :maxlength="18"
                                style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item prop="area" required label="收货人地区">
                      <!--<el-input v-model="pageForm.area"-->
                      <!--          style="width: 100%;"></el-input>-->
                      <el-cascader ref="areaCascaderRef"
                                   v-model="pageForm.area"
                                   :options="pca_options"
                                   :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :offset="2" :span="8">
                    <el-form-item prop="address" required label="详细地址">
                      <el-input v-model="pageForm.address"
                                style="width: 100%;"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

              </el-form>
            </div>
          </div>

          <div v-if="metadata.state === 'INIT'"
               class="descriptions"
               style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">操作</div>
            </div>
            <div class="descriptions-view">
              <el-button @click="closePage">取消</el-button>
              <el-button type="primary" @click="receipt">发货</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {fileUpload, getMaterialInfo, materialCheck} from "@/api/apis";
import {deliveryInventoryVerifily, exportPicture, isImage, viewImages} from "@/utils/common";
import helper, {projectMaterialStatusMapper} from "@/utils/helper";
import pcaCode from "@/utils/pca-code";
import FileList from "@/components/FileList";
export default {
  name: "Info",
  components: {FileList, PageHeader},
  data(){
    return {
      getData: getMaterialInfo,
      id: '',
      metadata: {},

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
      designDataFiles: [],
      designDataFilesStr: {},

      //
      fileList: [],
      pca_options: pcaCode,

      //
      pageForm: {
        // 发货清单
        order: [],
        // 司机姓名
        driverName: '',
        // 司机电话
        driverPhone: '',
        // 收货人姓名
        takeName: '',
        // 收货人电话
        takePhone: '',
        // 所在地区
        area: [],
        // 详细地址
        address: '',
      },
      pageFormRules: {
        order: [
          { required: true, message: '请上传发货清单' }
        ],
        driverName: [
          { required: true, message: '请填写司机姓名' }
        ],
        driverPhone: [
          { required: true, message: '请填写司机电话' }
        ],
        takeName: [
          { required: true, message: '请填写收货人姓名' }
        ],
        takePhone: [
          { required: true, message: '请填写收货人电话' }
        ],
        area: [
          { required: true, message: '请选择所在地区' }
        ],
        address: [
          { required: true, message: '请填写详细地址' }
        ],
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    designStatusMapper: projectMaterialStatusMapper,
    viewImages,
    isImage,
    showField(val, spec=''){
      if (val) {
        return val + spec
      }
      return ''
    },
    areaChange(val) {
      // this.pageForm.province = val[0]
      // this.pageForm.city = val[1]
      // this.pageForm.area = val[2]
      this.pageForm.area = [val[0], val[1], val[2]].join(' ')
    },
    /**
     * 取消
     */
    closePage(){
      this.$router.replace('/admin/material')
    },
    /**
     * 确认收货
     */
    receipt(){
      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          return false
        }
        // params
        const params = { ...this.pageForm }
        params.area = this.pageForm.area.join(' ')
        // 发货清单
        const files = []
        this.fileList.forEach(iter => {
          files.push(iter.value)
        })
        params.order = files.join(',')

        params.materialId = this.id

        // submit
        this.$confirm('确认发货吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({ fullscreen: true })
          materialCheck(params).then(() => {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.closePage()
            }, 500)
          }).finally(() => {
            loading.close()
          })
        })
      })
    },
    /**
     * 自定义文件上传
     */
    handlerUpload(e){
      const res = deliveryInventoryVerifily(e.file.name)
      if (res) {
        const loading = this.$loading({ fullscreen: true })
        fileUpload(e.file).then(res => {
          const name = ((res || '').split('/') || []).reverse()[0]
          this.fileList.push({
            name: name,
            url: helper.getSourceUri(res),
            value: res
          })
          this.pageForm.order.push(res)
        }).finally(() => {
          loading.close()
        })
      }else {
        this.$message.warning('文件格式不正确')
      }

      this.$refs.filesUpload.uploadFiles = this.$refs.filesUpload.uploadFiles.filter(iter => iter.status === 'success')
    },
    /**
     * 移除文件
     * @param index
     */
    removeHandler(index){
      this.fileList.splice(index, 1)
    },
    //---------------------------------------------------------------
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.design?.designData

          this.designDataFiles = this.showFileList(this.designData)
          this.designDataFilesStr = this.designData
        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>